import React from 'react'
import './ChatApp.scss'
import { AiFillCamera, AiFillCloseCircle, AiOutlineClose } from 'react-icons/ai';
import jquery from "jquery";
import { IoIosSend } from 'react-icons/io';
import { MdFitScreen } from 'react-icons/md';
import { FaUsers } from 'react-icons/fa';
import { BsFillChatDotsFill } from 'react-icons/bs';





const ChatApp = () => {
    const chatbox = jquery.noConflict();

chatbox(() => {
  chatbox(".chatbox-open").click(() =>
    chatbox(".chatbox-popup, .chatbox-close").fadeIn()
  );

  chatbox(".chatbox-close").click(() =>
    chatbox(".chatbox-popup, .chatbox-close").fadeOut()
  );

  chatbox(".chatbox-maximize").click(() => {
    chatbox(".chatbox-popup, .chatbox-open, .chatbox-close").fadeOut();
    chatbox(".chatbox-panel").fadeIn();
    chatbox(".chatbox-panel").css({ display: "flex" });
  });

  chatbox(".chatbox-minimize").click(() => {
    chatbox(".chatbox-panel").fadeOut();
    chatbox(".chatbox-popup, .chatbox-open, .chatbox-close").fadeIn();
  });

  chatbox(".chatbox-panel-close").click(() => {
    chatbox(".chatbox-panel").fadeOut();
    chatbox(".chatbox-open").fadeIn();
  });
});

  return (

    
    
    
    <div>
        <button className="chatbox-open  ">
    <i className="fa fa-comment fa-2x" aria-hidden="true">
    <BsFillChatDotsFill
    
    size={20}/>
    </i>
  </button>
{/* <button className="chatbox-close">
 

   <AiFillCloseCircle
   size={20}
 aria-hidden="true"
 />
   
  </button> */}
 <div className='sec-chat-i'>
<section className="chatbox-popup">
  <header className="chatbox-popup__header">
    <aside style={{flex:"3"}}>

      <FaUsers
      className='user-team-svg'
      size={35}
      
      />

    </aside>
    <aside style={{flex:"8"}}>
      <h1>Contest Prep Team</h1>(Online)
    </aside>
    <aside 
     className='yu'
    style={{flex:""}}>
      <button className="chatbox-maximize"><i className="fa fa-window-maximize" aria-hidden="true">
      <MdFitScreen
      style={{fontSize:'20px'}}
      />
        </i></button>
        <button className="chatbox-panel-close "><i >
      <AiOutlineClose
   
         style={{fontSize:'20px'}}
         
         className="chatbox-close" aria-hidden="true"
         />
        </i></button>
    </aside>
  </header>
  <main className="chatbox-popup__main">
    We make it simple and seamless for<br/> bussiness and people to talk to each<br/> other. Ask us anything.
  </main>
  <footer className="chatbox-popup__footer">
    <aside style={{flex:'1',color:'#888',textAlign:'center'}}>
    <AiFillCamera
    
    style={{fontSize:'20px'}}
    className="fa fa-camera" aria-hidden="true"/>
 
    </aside>
    <aside style={{flex:'10'}}>
      <textarea type="text" placeholder="Type your message here..." autofocus></textarea>
    </aside>
    <aside style={{flex:'1',color:'#888',textAlign:'center'}}>
    <IoIosSend
    style={{fontSize:'20px'}}
    className="fa fa-paper-plane" aria-hidden="true"
    />
   
    </aside>
  </footer>
</section>
<div>

<section className="chatbox-panel">
  <header className="chatbox-panel__header">
    <aside style={{flex:'3'}}>     
      <FaUsers
      className='user-team-svg'

      size={35}
      

      />
    </aside>
    <aside style={{flex:'6'}}>
    <h1>Contest Prep Team</h1>(Online)
    </aside>
    <aside 
    className='close-m'
    style={{flex:'3',textAlign:'right'}}>
      <button className="chatbox-minimize"><i className="fa fa-window-restore" aria-hidden="true">
      <MdFitScreen
      style={{fontSize:'20px'}}/>
        </i></button>
      <button className="chatbox-panel-close"><i >
      <AiOutlineClose
         style={{fontSize:'20px'}}
         
         className="" aria-hidden="true"
         />
        </i></button>
        
    </aside>
  </header>
  <main className="chatbox-panel__main" style={{flex:'1'}}>
    We make it simple and seamless for<br/> bussiness and people to talk to each<br/> other. Ask us anything.
  </main>
  <footer className="chatbox-panel__footer">
    <aside style={{flex:'1',color:'#888',textAlign:'center'}}>
    <AiFillCamera
    style={{fontSize:'20px'}}
    className="fa fa-camera" aria-hidden="true"/>
 
    </aside>
    <aside style={{flex:'10'}}>
      <textarea type="text" placeholder="Type your message here..." autofocus></textarea>
    </aside>
    <aside style={{flex:'1',color:'#888',textAlign:'center'}}>
      <IoIosSend
    style={{fontSize:'20px'}}
    className="fa fa-paper-plane" aria-hidden="true"
    />
    </aside>
  </footer>
</section>
    </div>
    </div>
    </div>
  )
}

export default ChatApp