import React, { useEffect, useState } from "react";
import Sidebar1 from "../Sidebar/Sidebar1";
import './Home.css'

const Home = () => {


    
  return (
    <div className='bg-hh'>

     
  <div className="main-div-h-d">
  <div>
      {/* <Sidebar1/> */}
      </div>
      <div>

        <div className='home-p-gg'>

        <div className='top-ii'>
            <img className='img-home-g' src={require('../Img/home1.jpg')} alt="" />
        </div>
        <div className='log-2'>
        <img className="home-logo" src={require("../Img/logo.png")} alt="" />
        <img className='img-home-g1' src={require('../Img/home1.jpg')} alt="" />

        </div>
        <div className='log-2'>
        <img className="home-logo" src={require("../Img/logo.png")} alt="" />
        <img className='img-home-g1' src={require('../Img/home2.jpg')} alt="" />

        </div>
        <div className='log-2'>
        <img className="home-logo" src={require("../Img/logo.png")} alt="" />
        <img className='img-home-g2' src={require('../Img/home2.jpg')} alt="" />

        </div>
        </div>
      </div>

    </div>
  </div>
  )
}

export default Home