import { increment } from "firebase/firestore";
import React, { useState } from "react";
import "./Contact.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
  // const notify = () => toast("Wow so easy!");

  // const [counter,setCounter]=useState(0);

  // const increment = () =>{
  //   setCounter(counter + 1);

  // }
  // const decrement = () =>{
  //   if(counter>0){
  //     setCounter(counter -1);
  //   }else{
  //     setCounter=(counter);

  //   }
  // }
// const [counter,setCounter]=useState(0);
// const increment = () =>{
//   setCounter(counter + 1);
// }
// const decrement = () =>{
//   if(counter>0){
//     setCounter(counter - 1);
//   }else{
//     setCounter(counter)
//   }
// }
  return (
    <div className="bg-contact">
      <div className="head-pp">
        <h1 className="contact-h1">Feel Free To Contact Us! </h1>
        <div style={{textAlign:'center'}}>

        <h1 className="contact-h2">+91 9876543210 </h1>
        <h1 className="contact-h3">example@gmail.com</h1>
        </div>
      </div>

      {/* <div className="load"></div>
  
        <ToastContainer /> */}

    </div>
    


  );
};

export default Contact;
