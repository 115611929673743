import React, { useEffect, useState } from "react";
import "./Sidenav.scss";
import "./Blog.css";
import { StyledOffCanvas, Menu, Overlay } from "styled-off-canvas";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { auth } from "../Firebase/Firebase";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ChatApp from "./ChatApp";
import { FiMenu } from "react-icons/fi";
import "./Sidebar.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { AiOutlineClose } from "react-icons/ai";
import Sidebar1 from "../Sidebar/Sidebar1";

// import "react-tabs/style/react-tabs.css";

const Blog = () => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const [user, loading] = useAuthState(auth);
  const [regis] = useAuthState(auth);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) {
      navigate("/");
    }
  }, [user, loading, navigate]);

  console.log(user);
  const logout = () => {
    signOut(auth);
    alert("LogOut");
    // toast("Added Successfully");
    navigate("/");
  };
  const log = () => {
    // toast("Added Successfully");
    signOut(auth);
    // alert('Login')
    navigate("/");
  };
  console.log(regis);
  return (
    <div className="bg-blog">
      <div className="fle-main-div">
        <div>
          <Sidebar1 />
        </div>

        <div className="tabs-1">
          <Tabs>
            <TabList>
              <div>
                <StyledOffCanvas
                  isOpen={isOpen}
                  onClose={() => setIsOpen(false)}
                >
                  <div>
                    <div>
                      <FiMenu
                        className="svg-open svg-side"
                        onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <div className="pav-head">
                      <Menu className="head-pvt">
                        <section class="app">
                          <aside class="sidebar">
                            <div className="close-div-svg">
                              <AiOutlineClose
                                className="svg-side close-svg1"
                                onClick={() => setIsOpen(!isOpen)}
                              />
                            </div>
                            <div className="head-h">
                              <header className="head-font">
                                Contest Prep
                              </header>
                            </div>
                            <nav class="sidebar-nav">
                              <ul className="ul-bl">
                                <li className="li-u2">
                                  <div className="tab-acc">
                                    <Accordion allowZeroExpanded={true}>
                                      <AccordionItem>
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            MODULES
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <div className="tab-s-p">
                                            <Tab className="tab-1">
                                              MODULE 1 - INTRODUCTION
                                            </Tab>

                                            <Tab className="tab-1">
                                              MODULE 2 - NUTRITION MODULE
                                            </Tab>

                                            <Tab className="tab-1">
                                              MODULE 3 - TRAINING
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 4 - RECOVERY TOOLS
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 5 - ANALYSING THE ORGAN
                                              HEALTH AND LAB MARKERS
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 6 - PERFORMANCE ENHANCING
                                              DRUGS
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 7 - OFF-SEASON
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 8 - CONTEST PREP
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 9 - GEETING READY FOR THE
                                              STAGE
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 10 - POST SHOW
                                            </Tab>
                                          </div>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                      <AccordionItem>
                                        <AccordionItemHeading>
                                          <AccordionItemButton>
                                            MODULES
                                          </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                          <div className="tab-s-p">
                                            <Tab className="tab-1">
                                              MODULE 1 - INTRODUCTION
                                            </Tab>

                                            <Tab className="tab-1">
                                              MODULE 2 - NUTRITION MODULE
                                            </Tab>

                                            <Tab className="tab-1">
                                              MODULE 3 - TRAINING
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 4 - RECOVERY TOOLS
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 5 - ANALYSING THE ORGAN
                                              HEALTH AND LAB MARKERS
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 6 - PERFORMANCE ENHANCING
                                              DRUGS
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 7 - OFF-SEASON
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 8 - CONTEST PREP
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 9 - GEETING READY FOR THE
                                              STAGE
                                            </Tab>
                                            <Tab className="tab-1">
                                              MODULE 10 - POST SHOW
                                            </Tab>
                                          </div>
                                        </AccordionItemPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  </div>
                                  <button
                                    className="get"
                                    onClick={() => log("/")}
                                  >
                                    Log out
                                  </button>
                                </li>
                                {/* <hr className="hr-blog" /> */}
                              </ul>
                            </nav>
                          </aside>
                        </section>
                      </Menu>
                    </div>
                  </div>

                  <Overlay />
                </StyledOffCanvas>
              </div>
            </TabList>
            <TabPanel>
              <div className="paf-g tab-panel-i">
                <div className="tab-oi">
                  <div>
                    <h1 className="intro-o">MODULE 1 - INTRODUCTION</h1>
                  </div>

                  <div className="blo-int">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Esse similique earum quos modi nostrum dolor aliquam
                      numquam iure, ex neque voluptas debitis, voluptatibus
                      expedita error vel veniam placeat eos quas. Lorem ipsum,
                      dolor sit amet consectetur adipisicing elit. Accusantium
                      corrupti deserunt aliquam suscipit quod autem quae
                      quibusdam illum. Est similique laboriosam id aliquam
                      consectetur assumenda doloremque tempora soluta quaerat
                      eligendi.
                    </p>
                    <div className="i-frame-o">
                      <iframe
                        className="iframe-w-v"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Ha6_bK8OYpA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="paf-g tab-panel-i">
                <div className="tab-oi">
                  <div>
                    <h1 className="intro-o">MODULE 2 - NUTRITION MODULE</h1>
                  </div>

                  <div className="blo-int">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Esse similique earum quos modi nostrum dolor aliquam
                      numquam iure, ex neque voluptas debitis, voluptatibus
                      expedita error vel veniam placeat eos quas. Lorem ipsum,
                      dolor sit amet consectetur adipisicing elit. Accusantium
                      corrupti deserunt aliquam suscipit quod autem quae
                      quibusdam illum. Est similique laboriosam id aliquam
                      consectetur assumenda doloremque tempora soluta quaerat
                      eligendi.
                    </p>
                    <div className="i-frame-o">
                      <iframe
                        className="iframe-w-v"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Ha6_bK8OYpA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="paf-g tab-panel-i">
                <div className="tab-oi">
                  <div>
                    <h1 className="intro-o">MODULE 3 - TRAINING</h1>
                  </div>

                  <div className="blo-int">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Esse similique earum quos modi nostrum dolor aliquam
                      numquam iure, ex neque voluptas debitis, voluptatibus
                      expedita error vel veniam placeat eos quas. Lorem ipsum,
                      dolor sit amet consectetur adipisicing elit. Accusantium
                      corrupti deserunt aliquam suscipit quod autem quae
                      quibusdam illum. Est similique laboriosam id aliquam
                      consectetur assumenda doloremque tempora soluta quaerat
                      eligendi.
                    </p>
                    <div className="i-frame-o">
                      <iframe
                        className="iframe-w-v"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Ha6_bK8OYpA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="paf-g tab-panel-i">
                <div className="tab-oi">
                  <div>
                    <h1 className="intro-o">MODULE 4 - RECOVERY TOOLS</h1>
                  </div>

                  <div className="blo-int">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Esse similique earum quos modi nostrum dolor aliquam
                      numquam iure, ex neque voluptas debitis, voluptatibus
                      expedita error vel veniam placeat eos quas. Lorem ipsum,
                      dolor sit amet consectetur adipisicing elit. Accusantium
                      corrupti deserunt aliquam suscipit quod autem quae
                      quibusdam illum. Est similique laboriosam id aliquam
                      consectetur assumenda doloremque tempora soluta quaerat
                      eligendi.
                    </p>
                    <div className="i-frame-o">
                      <iframe
                        className="iframe-w-v"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Ha6_bK8OYpA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="paf-g tab-panel-i">
                <div className="tab-oi">
                  <div>
                    <h1 className="intro-o">
                      MODULE 5 - ANALYSING THE ORGAN HEALTH AND LAB MARKERS
                    </h1>
                  </div>

                  <div className="blo-int">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Esse similique earum quos modi nostrum dolor aliquam
                      numquam iure, ex neque voluptas debitis, voluptatibus
                      expedita error vel veniam placeat eos quas. Lorem ipsum,
                      dolor sit amet consectetur adipisicing elit. Accusantium
                      corrupti deserunt aliquam suscipit quod autem quae
                      quibusdam illum. Est similique laboriosam id aliquam
                      consectetur assumenda doloremque tempora soluta quaerat
                      eligendi.
                    </p>
                    <div className="i-frame-o">
                      <iframe
                        className="iframe-w-v"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Ha6_bK8OYpA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="paf-g tab-panel-i">
                <div className="tab-oi">
                  <div>
                    <h1 className="intro-o">
                      MODULE 6 - PERFORMANCE ENHANCING DRUGS
                    </h1>
                  </div>

                  <div className="blo-int">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Esse similique earum quos modi nostrum dolor aliquam
                      numquam iure, ex neque voluptas debitis, voluptatibus
                      expedita error vel veniam placeat eos quas. Lorem ipsum,
                      dolor sit amet consectetur adipisicing elit. Accusantium
                      corrupti deserunt aliquam suscipit quod autem quae
                      quibusdam illum. Est similique laboriosam id aliquam
                      consectetur assumenda doloremque tempora soluta quaerat
                      eligendi.
                    </p>
                    <div className="i-frame-o">
                      <iframe
                        className="iframe-w-v"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Ha6_bK8OYpA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="paf-g tab-panel-i">
                <div className="tab-oi">
                  <div>
                    <h1 className="intro-o">MODULE 7 - OFF-SEASON</h1>
                  </div>

                  <div className="blo-int">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Esse similique earum quos modi nostrum dolor aliquam
                      numquam iure, ex neque voluptas debitis, voluptatibus
                      expedita error vel veniam placeat eos quas. Lorem ipsum,
                      dolor sit amet consectetur adipisicing elit. Accusantium
                      corrupti deserunt aliquam suscipit quod autem quae
                      quibusdam illum. Est similique laboriosam id aliquam
                      consectetur assumenda doloremque tempora soluta quaerat
                      eligendi.
                    </p>
                    <div className="i-frame-o">
                      <iframe
                        className="iframe-w-v"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Ha6_bK8OYpA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="paf-g tab-panel-i">
                <div className="tab-oi">
                  <div>
                    <h1 className="intro-o">MODULE 8 - CONTEST PREP</h1>
                  </div>

                  <div className="blo-int">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Esse similique earum quos modi nostrum dolor aliquam
                      numquam iure, ex neque voluptas debitis, voluptatibus
                      expedita error vel veniam placeat eos quas. Lorem ipsum,
                      dolor sit amet consectetur adipisicing elit. Accusantium
                      corrupti deserunt aliquam suscipit quod autem quae
                      quibusdam illum. Est similique laboriosam id aliquam
                      consectetur assumenda doloremque tempora soluta quaerat
                      eligendi.
                    </p>
                    <div className="i-frame-o">
                      <iframe
                        className="iframe-w-v"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Ha6_bK8OYpA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="paf-g tab-panel-i">
                <div className="tab-oi">
                  <div>
                    <h1 className="intro-o">
                      MODULE 9 - GEETING READY FOR THE STAGE
                    </h1>
                  </div>

                  <div className="blo-int">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Esse similique earum quos modi nostrum dolor aliquam
                      numquam iure, ex neque voluptas debitis, voluptatibus
                      expedita error vel veniam placeat eos quas. Lorem ipsum,
                      dolor sit amet consectetur adipisicing elit. Accusantium
                      corrupti deserunt aliquam suscipit quod autem quae
                      quibusdam illum. Est similique laboriosam id aliquam
                      consectetur assumenda doloremque tempora soluta quaerat
                      eligendi.
                    </p>
                    <div className="i-frame-o">
                      <iframe
                        className="iframe-w-v"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Ha6_bK8OYpA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="paf-g tab-panel-i">
                <div className="tab-oi">
                  <div>
                    <h1 className="intro-o">MODULE 10 - POST SHOW</h1>
                  </div>

                  <div className="blo-int">
                    <p>
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Esse similique earum quos modi nostrum dolor aliquam
                      numquam iure, ex neque voluptas debitis, voluptatibus
                      expedita error vel veniam placeat eos quas. Lorem ipsum,
                      dolor sit amet consectetur adipisicing elit. Accusantium
                      corrupti deserunt aliquam suscipit quod autem quae
                      quibusdam illum. Est similique laboriosam id aliquam
                      consectetur assumenda doloremque tempora soluta quaerat
                      eligendi.
                    </p>
                    <div className="i-frame-o">
                      <iframe
                        className="iframe-w-v"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/Ha6_bK8OYpA"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
          <ChatApp />
        </div>
      </div>

    </div>
  );
};

export default Blog;
