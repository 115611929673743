import React from 'react'
import { useNavigate } from 'react-router-dom';
import './Signup.css'
const Signup = () => {
  const navigate=useNavigate();

  return (
    <div className='sig-bg'>
      <div className='log-form-p1'>
        <div className='logo-pp51'>
          <img src={require('../Img/logo.png')} alt="" />
        </div>
        <h1 className='log-h11'>SIGN UP</h1>
        <div className='log-form'> 
          <form>
            <div className='log-fgg1'>
              <input className='input-log1' type="email"
              placeholder='Email'
              name="email" id="" />
              <input 
              className='input-log1'
               placeholder='Password'
              type="password" name="" id="" />
<button
          onClick={()=> navigate('/')}
          className="log-bb11">SIGN UP</button>
              
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Signup