import React, { useState } from "react";
import { BsChatSquareText } from "react-icons/bs";
import { CiStreamOn } from "react-icons/ci";
import { FaBookmark } from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { TbListDetails } from "react-icons/tb";
import { StyledOffCanvas, Menu, Overlay } from "styled-off-canvas";
import { HiUserCircle } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GrMenu } from "react-icons/gr";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <StyledOffCanvas isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <button onClick={() => setIsOpen(!isOpen)}><GrMenu className="open-tab-mob"/></button>

        <Menu>
          <div className="head-mobile-menu">
            <ul className="ul-menu-mobile">
              <li>
                <div className="mobile-k-m">
                  <div className="user-name-mobile-menu">
                    <HiUserCircle />
                    <div className="user-m">
                      <h1>User !</h1>
                      <h1>My Account</h1>
                    </div>
                  </div>
                  <div>
                    <a onClick={() => setIsOpen(false)}>
                      <AiOutlineClose
                      className="close-mobile-menu"
                      />
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div className="side-menu-h1">
                  <ImBooks />
                  <Link to="/Blog">
                    <span className="link-k">STUDY DETAILS</span>
                  </Link>
                </div>
              </li>

              <li>
                <div className="side-menu-h1">
                  <BsChatSquareText />
                  <Link to="/Blog">
                    <span className="link-k">FORUMS</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="side-menu-h1">
                  <CiStreamOn />
                  <Link to="/Blog">
                    <span className="link-k">LIVE STREAM</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="side-menu-h1">
                  <TbListDetails />
                  <Link to="/Course">
                    <span className="link-k">COURSE DETAILS</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="side-menu-h1">
                  <FaBookmark />
                  <Link to="/Blog">
                    <span className="link-k">ABOUT US</span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </Menu>

        <Overlay />

        <div>this is some nice content!</div>
      </StyledOffCanvas>
    </div>
  );
};

export default Sidebar;
