import React, { useEffect, useLayoutEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, } from "react-router-dom";
import Aboutus from "./Pages/Aboutus";
import Course from "./Pages/Course";
import Home from "./Pages/Home";
import Navbar from "./Pages/Navbar";
import Blog from "./Pages/Blog";
import Contact from "./Pages/Contact";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import ChatApp from "./Pages/ChatApp";
import Sidebar from "./Pages/Sidebar";
import Sidebar1 from "./Sidebar/Sidebar1";
import ScrollToTop from "./Scrollontop";
// import Side from "./sidebar/Side";


const App = () => {
  return (
    <>
      <Router>
<ScrollToTop/>
        <Navbar />
        {/* <Sidebar1/> */}

        <Routes>


          <Route path="/Home" element={<Home />} />
          <Route path="/AboutUs" element={<Aboutus />} />
          <Route path="/Course" element={<Course />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/" element={<Login />} />
          <Route path="/SignUp" element={<Signup/>} />
          <Route path="/Si" element={<Sidebar/>} />

          <Route path="/Chat" element={<ChatApp/>} />
          {/* <Route path="/Sidebar" element={<Sidebar1/>} /> */}

        </Routes>

        {/* <Footer /> */}
      </Router>
    </>
  );
};

export default App;
