import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { BsChatSquareText } from "react-icons/bs";
import { CiStreamOn } from "react-icons/ci";
import { FaBookmark } from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { TbListDetails } from "react-icons/tb";
import { StyledOffCanvas, Menu, Overlay } from "styled-off-canvas";
import { HiUserCircle } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { GrMenu } from "react-icons/gr";
const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  let activeStyle = {
    // textDecoration: "underline",
    borderBottom: "2px solid white",
    // padding: "2px",
    // textunderlineOffset: '8px',
  };

  return (
    <div className="nav-bg">
      <div>

      <div className="fle1-nav">
        <div className="logo-1">
          <img  
          onClick={()=> navigate('/Home')}
          className="nav-logo" src={require("../Img/logo.png")} alt="" />
        </div>
          <div>
        <div className="ul-n">

          <NavLink 
           style={({ isActive }) => (isActive ? activeStyle : undefined)}
           to={"/Home"} className="nav-a">Home</NavLink>
          {/* <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to={"/AboutUs"}
              className="nav-a">About Us</NavLink>
          <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to={"/Course"}
              className="nav-a">Course Details</NavLink>
          <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to={"/Blog"}
              className="nav-a">Study Details</NavLink>
          <NavLink 
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
           to={"/Contact"}
           className="nav-a">Contact Us</NavLink> */}
                {/* <NavLink 
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to={"Chat"}
           className="nav-a">Chat</NavLink> */}
        </div>
           </div>

        <div className="nav-bb">
        <div class='bodys'>
<details class="dropdown">
    <summary role="button">
      <a class="button">user</a>
    </summary>
    <ul>
      <li><a href="#">User</a></li>
      <li><a href="#">In Pure CSS</a></li>
      <li><a href="#">As in...</a></li>
      <li><a href="#">No JavaScript.</a></li>
      <li><a href="#">At All.</a></li>
  </ul>
</details>
</div>
          <button  onClick={()=> navigate('/')} className="login-b">LOG IN</button>
          <button
          onClick={()=> navigate('/SignUp')}
          className="sign-bb">SIGN UP</button>


          
                         {/* mobile-menu */}
    <div className="mobile-menu-nav">
      <StyledOffCanvas isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <button onClick={() => setIsOpen(!isOpen)}><GrMenu className="open-tab-mob"/></button>

        <Menu>
          <div className="head-mobile-menu">
            <ul className="ul-menu-mobile">
              <li>
                <div className="mobile-k-m">
                  <div className="user-name-mobile-menu">
                    <HiUserCircle />
                    <div className="user-m">
                      <h1>User !</h1>
                      <h1>My Account</h1>
                    </div>
                  </div>
                  <div>
                    <a onClick={() => setIsOpen(false)}>
                      <AiOutlineClose
                      className="close-mobile-menu"
                      />
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <div className="side-menu-h1">
                  <ImBooks />
                  <Link to="/Blog">
                    <span className="link-k">STUDY DETAILS</span>
                  </Link>
                </div>
              </li>

              <li>
                <div className="side-menu-h1">
                  <BsChatSquareText />
                  <Link to="/Blog">
                    <span className="link-k">FORUMS</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="side-menu-h1">
                  <CiStreamOn />
                  <Link to="/Blog">
                    <span className="link-k">LIVE STREAM</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="side-menu-h1">
                  <TbListDetails />
                  <Link to="/Course">
                    <span className="link-k">COURSE DETAILS</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="side-menu-h1">
                  <FaBookmark />
                  <Link to="/Blog">
                    <span className="link-k">ABOUT US</span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </Menu>

        <Overlay />

      </StyledOffCanvas>
    </div>
        </div>
      </div>
      <hr />
    </div>

              </div>
  );
};

export default Navbar;
