import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, logInWithEmailAndPassword } from "../Firebase/Firebase";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  return (
    <div className="log-bg">
      <div className="log-form-p">
        <div className="logo-pp5">
          <img src={require("../Img/logo.png")} alt="" />
        </div>
        <h1 className="log-h1">LOG IN</h1>
        <div className="log-form">
          {/* <form> */}
          <div className="log-fgg">
            <input
              required
              className="input-log"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              name="email"
              id=""
            />
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-log"
              placeholder="Password"
              type="password"
              name=""
              id=""
            />
            <button
              onClick={() =>
                logInWithEmailAndPassword(email, password).then(() =>
                  navigate("/blog")
                )
              }
              // onClick={()=> navigate('./SignUp')}
              className="log-bb1"
            >
              LOG IN
            </button>
          </div>
          {/* </form> */}
        </div>
      </div>
    </div>
  );
};

export default Login;
